<template>
  <div class="auth" id="create-pwd">
    <section class="hero is-fullheight">
      <div class="header">
        Bienvenue
      </div>
      <div class="hero-body">
        <div class="auth-block">
          <div class="auth-form">
            <form @submit.prevent="create">
              <p class="mb-4">Veuillez créer votre mot de passe</p>
              <b-field>
                <b-input type="password" v-model="password" required size="large"
                  password-reveal icon="fas fa-key"
                  placeholder="Mot de passe"></b-input>
              </b-field>
              <b-field>
                <b-input type="password" v-model="passwordConfirmation" required size="large"
                  password-reveal icon="fas fa-key"
                  placeholder="Confirmation du mot de passe"></b-input>
              </b-field>
              <b-button type="is-primary" v-bind:disabled="!password || !passwordConfirmation" native-type="submit" expanded>
                Valider mon mot de passe
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'CreatePassword',
  data () {
    return {
      password: '',
      passwordConfirmation: ''
    }
  },
  methods: {
    create () {
      const { password, passwordConfirmation } = this
      this.$store.dispatch('AUTH_LOGIN', { url: '/auth-admin', payload: { token: this.$route.params.token, password, password_confirmation: passwordConfirmation } }).then(() => {
        this.$buefy.notification.open({
          duration: 4000,
          message: 'Le mot de passe a bien été créé',
          position: 'is-bottom',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push('/app/dashboard')
      })
    }
  }
}
</script>

<style lang="scss">
body {
  background: whitesmoke;
}
.hero-body {
  justify-content: center;
  flex-direction: column;
}

#create-pwd {
  .hero-body {
    background-color: rgb(248, 247, 247);
    color: #000;
    padding: 1.5rem
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    button {
      border-radius: 1rem;
      background-color: rgb(45,93,251);
    }
  }

  input {
    border-radius: 1rem;
  }

  .auth-form {
    color: #000;
    box-shadow: none;
    padding: 0;
  }

  .header {
    background: #2F4AF4;
    color: #fff;
    height: 9rem;
    padding: 1.5rem;
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0.025em;
  }
}

.login {
  background: white;
  padding: 25px;
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
}
.login-hr {
  border-bottom: 1px solid black;
}
</style>
